export const colors = {
  priceWishlistColor: '#333',
  buttonPrimaryColor: '#f80032',
  primaryColor: '#F80032',
  secondaryColor: '#290021',
  ameColor: '#FF006F',
  blue: '#24CBFF',
  yellow: '#FAD70A',
  green: '#66CE02',
  white: '#fff',
  black: '#000000',
  primaryColorTransparent: '#f8003214',
  buttonLikeColor: '#369E26',
  buttonDislikeColor: '#f80032',
  success: {
    lighter: '#EFF8EA',
    light: '#DCEDC9',
    medium: '#40cd28',
    dark: '#00B100',
    darker: '#68CD00',
  },
  error: {
    light: '#FFEBEE',
    medium: '#f80032',
  },
  warning: {
    light: '#fbeec1',
    medium: '#F2C832',
  },
  info: {
    lighter: '#E8FAFD',
    light: '#e1f5fe',
    medium: '#24cbff',
    dark: '#00B4D2',
    darker: '#147FE8',
  },
  grey: {
    lighter: '#f1f1f1',
    light: '#ccc',
    medium: '#999',
    dark: '#666',
    darker: '#333',
    font: '#9B9B9B',
  },
  red: {
    lighter: '#fcf5f5',
    light: '#ff3f4f',
    dark: '#ab0000',
  },
  card: {
    green: '#07AA07',
    badge: '#07AA07',
    ame: '#ED0059',
    cashback: '#AF2660',
  },
  textColor: '#666',
  titleColor: '#666',
  menuTextColor: '#35002A',
};

export const fontAndSpaces = {
  fontSize: {
    xxs: '12px',
    xs: '14px',
    sm: '16px',
    md: '18px',
    lg: '20px',
    xl: '24px',
    xxl: '30px',
  },
  fontWeight: {
    title: 'bold',
  },
  spacing: {
    xxs: '5px',
    xs: '10px',
    sm: '15px',
    md: '20px',
    lg: '35px',
    xl: '45px',
    xxl: '65px',
  },
  breakpoints: {
    xxs: '320px',
    xs: '480px',
    sm: '768px',
    md: '1025px',
    lg: '1280px',
    xl: '1360px',
  },
  titleFont: '18px',
  titleTransform: 'lowercase',
  btnTextTransform: 'lowercase',
  btnTextWeigth: '700',
  borderRadius: '8px',
};
