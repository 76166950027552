import { ThemeProvider as ThemeProviderSC } from 'styled-components';
import React from 'react';
import { withRouter } from 'react-router';
import { useSkin } from '@catalogo/service-americanas-skin';
import { colors, fontAndSpaces } from './theme-props';

export const PortalProvider = {
  fontFamily: 'Helvetica,Arial,sans-serif;',
  ...colors,
  ...fontAndSpaces,
  shadow: {
    floatBasket: '0 0 12px 0 rgba(0,0,0,0.25)',
    box: '0 6px 20px -1px rgba(0,0,0,0.08);',
  },
  border: '1px solid #CCCCCC',
  blackTranslucid: 'rgba(0,0,0,0.8)',
  loading: '#f80032',
};

const ThemeProvider = props => {
  const skin = useSkin(props.history?.location);

  return <ThemeProviderSC theme={{ ...PortalProvider, ...skin }} {...props} />;
};

export default withRouter(ThemeProvider);
